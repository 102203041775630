import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DyteMeeting } from "@dytesdk/react-ui-kit";
import { useDyteClient } from "@dytesdk/react-web-core";
import { provideDyteDesignSystem } from "@dytesdk/react-ui-kit";
import "../App.css";

function HostMeeting() {
  const { bubble_id } = useParams();
  const [meeting, initMeeting] = useDyteClient();
  const meetingEl = useRef(null);
  const hasInitialized = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const apiUrl = "https://streambean-api.vercel.app";
  const [leftMeeting, setLeftMeeting] = useState(false);

  useEffect(() => {
    const fetchHostDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/host/${bubble_id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching host details:", error);
        setError(error);
      }
    };

    fetchHostDetails();
  }, [bubble_id, apiUrl]);

  useEffect(() => {
    const initializeMeeting = async () => {
      if (!userDetails || hasInitialized.current) return;
      hasInitialized.current = true;
      setIsLoading(true);
      setError(null);

      try {
        const { auth_token, name } = userDetails;
        const token = String(auth_token);

        await initMeeting({
          authToken: token,
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Failed to join meeting:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    initializeMeeting();
  }, [initMeeting, userDetails]);

  useEffect(() => {
    if (userDetails && !userDetails.is_logged_in) {
      window.location.href = "https://go.streambean.ai/login";
    }
  }, [userDetails]);

  useEffect(() => {
    if (meeting) {
      meeting.self.on("roomLeft", () => {
        setLeftMeeting(true);

        window.location.href = `https://go.streambean.ai/`;
      });
    }
  }, [meeting]);

  useEffect(() => {
    if (meeting && meetingEl.current) {
      provideDyteDesignSystem(meetingEl.current, {
        theme: "light",
        googleFont: "Comfortaa",
        space: { 96: "600px" },
        colors: {
          brand: {
            500: userDetails?.primary_color || "#0e3d1e",
            700: userDetails?.primary_color || "#0e3d1e",
            600: userDetails?.secondary_color || "#6db445",
          },
          background: {
            700: "#FFFFFF",
            600: "#D3D3D3",
            800: userDetails?.primary_color || "#0e3d1e",
          },
          text: { 1000: userDetails?.secondary_color || "#6db445" },
          "video-bg": "#eaeaea",
          // danger: "#ff909e",
        },
      });
    }
  }, [meeting, userDetails]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <img
          src="/Stream-Bean-Meeting-Logo---Loading.png"
          alt="Loading"
          className="loading-img"
        />
      </div>
    );
  }
  if (error) return <div>Error: {error.message}</div>;

  if (leftMeeting) {
    return (
      <div className="leave-message-container">
        <img src="/sb-waiting.png" className="wait-img" />
        <h3 className="custom-leave-message">Please wait a sec...</h3>
      </div>
    );
  }
  return (
    <div className="app-container">
      {userDetails?.is_logged_in ? (
        <div className="meeting-container">
          <DyteMeeting
            ref={meetingEl}
            meeting={meeting}
            className="custom-dyte-meeting"
            style={{ "--dyte-space-96": "600px" }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default HostMeeting;
